<template>
  <div>
    <div class="admintop-mian ">
      <div>
        <template v-if="language=='zh'">
          <myimage :url='url'></myimage>
        </template>
        <template v-if="language=='TW'">
          <myimage :url='urlTW'></myimage>
        </template>
        <template v-if="language=='En'">
          <myimage :url='urlEn'></myimage>
        </template>
      </div>
      <div :class="screenWidth>=992?'adminmain-service hidden-xs-only':'adminmain-service adminmainpaddbottom70 hidden-xs-only' ">
        <div class="">
          <div class="seachina-text">{{$t('admission.首页')}} > {{$t('admission.录取捷报')}}</div>
          <div class="seaenglish-text">{{$t('admission.AdmissionsuccessReport')}}</div>
        </div>
        <div class="admissCon">
          <!-- 左边的点击内容 -->
          <div class="left-con">
            <div @click="toleftindex(index)" v-for="(item,index) in lefttype" :key='index'
                 :class="leftindex==index?'leftactive':''">{{ item }}
            </div>
          </div>
          <!-- 右边的列表内容 -->
          <div class="admissright-con">
            <div @click="toDetail(item.abroadId)" v-for="(item,index) in StudyAbroadList" :key='index'>
              <div class="admissright-con-img">
                <img :src="baseUrl+item.thumbnail" alt="">
              </div>
              <div class="admissright-con-text">
                <div class="admiss-top">{{item.title}}</div>
                <div class="admiss-con">
                  <!-- <template v-if="item.content.replace(/<[^>]+>/g, '').length>100">{{item.content.replace(/<[^>]+>/g, '').slice(0,100)}}...</template>
                  <template v-else>{{item.content.replace(/<[^>]+>/g, '')}}</template> -->
                  <p>{{item.subTitle}}</p>
                </div>
                <div class="admiss-icon">
                  <div>
                    <img src="../../assets/image/HKgoods/hot.png" alt="">
                    {{item.viewsCount||0}}
                  </div>
                  <div>
                    <img src="../../assets/image/HKgoods/time.png" alt="">
                     {{item.createDate.slice(5,10)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="HKmain-service-bot" :style="[{'height':companyheight}]">
          <div class="english-yellow">{{$t('admission.APPLICATIONPROCESS')}}</div>
          <div class="service-con">
            <div class="servicetop">{{$t('admission.支持')}}</div>
            <div class="servicebot"></div>
            <div class="all-service-company" >
              <div v-for="(item,index) in agencList" :key='index'  >
                <img :src="item.thumbnail" alt=""  >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 移动端 -->
      <div class="adminmain-service-sm hidden-sm-and-up adminmainpaddbottom20">
        <div class="">
          <div class="seachina-text-sm">{{$t('admission.首页')}} > {{$t('admission.录取捷报')}}</div>
          <div class="seaenglish-text-sm">Admission success Report</div>
        </div>
        <div class="admin-list-choose">
          <div v-for="(item,index) in lefttype" :key='index' @click="toleftindex(index)"  :class="leftindex==index?'leftactive':''">{{item}}</div>
        </div>
        <div class="admissright-con-sm">
          <div @click="toDetail(item.abroadId)"  v-for="(item,index) in StudyAbroadList" :key='index'>
              <div class="admissright-con-img-sm">
                <img :src="baseUrl+item.thumbnail" alt="">
              </div>
              <div>
                <div class="admiss-top-sm">{{item.title}}</div>
                <div class="admiss-con-sm" >
                  <!-- <template v-if="item.content.replace(/<[^>]+>/g, '').length>100">{{item.content.replace(/<[^>]+>/g, '').slice(0,100)}}...</template>
                  <template v-else>{{item.content.replace(/<[^>]+>/g, '')}}</template> -->
                  <p>{{item.subTitle}}</p>
                </div>
                <div class="admiss-icon-sm">
                  <div>
                    <img src="../../assets/image/HKgoods/hot.png" alt="">
                     {{item.viewsCount||0}}
                  </div>
                  <div>
                    <img src="../../assets/image/HKgoods/time.png" alt="">
                     {{item.createDate.slice(5,10)}}
                  </div>
                </div>
              </div>
          </div>
        </div>
         <div class="main-service-bot-sm" :style="[{'height':companyheight2}]">
          <div class="english-yellow-sm">{{$t('admission.APPLICATIONPROCESS')}}</div>
          <div class="service-con-sm">
            <div class="servicetop-sm">{{$t('admission.支持')}}</div>
            <div class="servicebot-sm"></div>
            <div class="all-service-company-sm" >
              <div v-for="(item,index) in agencList" :key='index' :style="[{'margin-right':companymargin}]" >
                <img :src="item.thumbnail" alt=""  >
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </div>
    <Mydialog ref="icon" @sumbit='sumbit'></Mydialog>
    <Myicon @iconchange='iconchange'></Myicon>
  </div>
</template>

<script>
import myimage from '@/components/myimage'
import Mydialog from "@/components/dialog"
import Myicon from '@/components/mainicon'
import "element-ui/lib/theme-chalk/display.css";
import {agencList,hongKongStudyAbroadList,addMessageRecord,addViewsCountHongKongStudyAbroad} from '@/api/index.js'

export default {
  components: {
    myimage,
    Mydialog,
    Myicon
  },
  data() {
    return {
      query: {
        pageNumber: 1,
        pageSize: 10
      },
      url:require('@/assets/image/lqjb.png'),
      urlTW:require('@/assets/image/lqjbTW.png'),
      urlEn:require('@/assets/image/lqjbEn.png'),
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      leftindex: 0,
      agencList:[],
      baseUrl:'',
      addressId:'',
      StudyAbroadList:[],//录取捷报列表
      language:'zh'
    }
  },
  computed:{
    companyheight(){
      if(this.agencList.length>0){
     return  Math.ceil(this.agencList.length/4)*110+250 +'PX'
      }else{
         return 300+'PX'
      }
    },
     companyheight2(){
      if(this.agencList.length>0){
        return (Math.ceil(this.agencList.length/Math.floor( 335/100))*110+40)+'PX'
      }else{
         return 100+'PX'
      }
    },
    companymargin2(){
       if(this.agencList.length>0){
        return Math.floor((this.screenWidth*0.9-3*224)/2)+'PX'
      }else{
         return 0+'PX'
      }
    },
    companymargin(){
      if(this.agencList.length>0){
        return Math.floor(( this.screenWidth*0.9-Math.floor(this.screenWidth*0.9/224)*224)/(Math.floor(this.screenWidth*0.9/224)-1))+'PX'
      }else{
         return 0+'PX'
      }
    },
    lefttype(){
      return [this.$t('admission.副学士'), this.$t('admission.本科'), this.$t('admission.研究生')]
    } 
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth; //实时宽度
        window.screenHeight = document.documentElement.clientHeight; //实时高度
        console.log(this.screenWidth, this.screenHeight);
        that.screenWidth = window.screenWidth;
        that.screenHeight = window.screenHeight;
      })();
    };
    window.addEventListener("resize", () => {
       that.$nextTick(() => {
        that.$forceUpdate()
      });
    });
  },
  created() {
    let type=5
    this.leftindex = this.$route.query.twoindex||0
    this.baseUrl=this.$store.state.baseUrl
    this.language=localStorage.getItem('language')
    this._agencList()
    if(this.leftindex==0){
       type=5
    }
     if(this.leftindex==1){
       type=6
    }
     if(this.leftindex==2){
       type=7
    }
    this._hongKongStudyAbroadList(4,type)
  },
  methods: {
    //在线咨询（成功案例）
    sumbit(e){
        e.category=7
      addMessageRecord(e).then(()=>{
        this.$message.success(this.$t('admission.已提交'))
      })
    },
    _hongKongStudyAbroadList(category,type){
      hongKongStudyAbroadList({
        category:category,
        type:type
      }).then(res=>{
        this.StudyAbroadList=res.data.slice(0,6)
        this.StudyAbroadList = this.StudyAbroadList.reverse()
      })
    },
    //获取团队
      _agencList(){
        agencList().then(res=>{
          console.log(res)
          res.data.forEach((item)=>{
            item.thumbnail=this.baseUrl+item.thumbnail
          })
          this.agencList=res.data
        })
      },
    iconchange(){
      this.$refs.icon.dialogVisible=true
    },
    toleftindex(e) {
      this.leftindex = e
      if(e==0){
        this._hongKongStudyAbroadList(4,5)
      }
      if(e==1){
        this._hongKongStudyAbroadList(4,6)
      }
      if(e==2){
        this._hongKongStudyAbroadList(4,7)
      }
    },
    toDetail(abroadId) {
      addViewsCountHongKongStudyAbroad({
        abroadId:abroadId
      }).then(()=>{
        this.$router.push({
        path: '/AsmissionDetail',
        query:{
          abroadId:abroadId
        }
      });
      })
      
    }
  }
}
</script>

<style scoped lang="scss">
page{
  background: #f7f7f7;
}
.admintop-mian {
  width: 100vw;
  background: #f7f7f7 !important;
}

.Hkpagination {
  margin: 0 auto;
  text-align: center !important;
}

.adminmain-service {
  width: 1176PX;
  margin: 0 auto;
  padding-bottom: 50PX;
  padding: 70PX 0;
}

.adminmain-service-sm {
  width: 335PX;
  margin: 0 auto;
  padding-bottom: 50PX;
}
.adminmainpaddbottom70{
  padding: 70PX 0;
}
.adminmainpaddbottom20{
  padding: 20PX 0 0 0;
}
.adminmain-service > div, .adminmain-service-sm > div {
  position: relative;
  top: 0;
  text-align: left;
}

.seachina-text {
  font-size: 20PX;
  font-weight: 600;
}

.seaenglish-text {
  padding-bottom: 24px;
  font-size: 28PX;
  color: #999999;
}

.admissCon {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left-con {
  background: #fff;
  font-size: 16PX;
  width: 250PX;
  height: 162PX;
}

.left-con > div {
  height: 54PX;
  line-height: 54PX;
  text-align: center;
  color: #333;
}

.leftactive {
  background: rgba(254, 153, 43, 0.2)!important;
  //font-weight: bold;
}

.admissright-con {
  width: calc(100% - 274PX);
  display: flex;
  flex-direction: column;
}

.admissright-con > div {
  display: flex;
  flex-direction: row;
  margin-bottom: 30PX;
  height: 156PX;
}

.admissright-con-img img{
  width: 228PX;
  height: 144PX;
  margin: 0 auto;
  margin-right: 16px;
}
.admissright-con-text{
  width: calc(100% - 228PX);
}
.admissright-con-img img {
  width: 228PX!important;
  height: 144PX!important;
}

.admiss-top {
  font-size: 20PX;
  margin-bottom: 12PX;
  color: #333;
}

.admiss-con {
  color: #999999;
  font-size: 16PX;
  height: 70PX;
}

.admiss-icon {
  display: flex;
  flex-direction: row;
  margin-top: 13PX;
  font-size: 16PX;
}

.admiss-icon > div {
  margin-right: 26PX;
}

.admiss-icon img {
  width: 23px;
  height: 23px;
  vertical-align: -3PX;
}

/* .HKmain-service-bot {
  height: 606px;
  text-align: center !important;
  padding: 70PX 0;
} */
.HKmain-service-bot {
  padding: 30PX 0;
} 

.all-service-company{
  width:1176PX;
  padding: 30PX 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20PX auto 0 auto;
}
.all-service-company>div{
  width: 280PX;
  height: 100PX;
  margin-bottom: 30PX;
  /*margin:0 calc(25% - 310PX) 30PX calc(25% - 310PX);*/
}
.all-service-company>div img{
width:280PX;
  height: 100PX;
}
.english-yellow {
  color: #FE992B;
  font-size: 44px;
  opacity: 0.3;
  text-align: center;
}

.service-con {
  width: 100%;
  position: absolute;
  top: 40px;
}

.servicetop {
  /* width: 150px; */
  font-size: 32px;
  color: #333;
  font-weight: bold;
  padding: 10PX 0;
  /* border-bottom: 6px solid #FE992B; */
  border-radius: 3px;
  margin: 0 auto;
  text-align: center;
}

.servicebot {
  width: 150px;
  height: 6PX;
  background: #FE992B;
  border-radius: 3px;
  margin: 0 auto;
}

.all-service {
  width: 100%;
  height: 392px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
}
.all-service img{
  width: 100%;
  height:400px;
}
/* 移动端 */
.seachina-text-sm {
  font-size: 18PX;
  font-weight: 600;
}

.seaenglish-text-sm {
  padding-bottom: 24px;
  font-size: 16PX;
  /* border-bottom: 2PX solid #FADBBA; */
  color: #999999;
  margin-bottom:20PX;
}
.admin-list-choose{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.admin-list-choose>div{
  width: 30%;
  height: 42PX;
  background: #fff;
  text-align: center;
  line-height: 42PX;
  font-size: 12PX;
}

.admissright-con-sm {
  width: 335PX;
  display: flex;
  flex-direction: column;
  margin: 16PX auto 0 auto;
  
}

.admissright-con-sm > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 22PX;
}

.admissright-con-img-sm img {
  width: 335PX;
  height: 212PX;
  margin-right: 16px;
}
.admiss-top-sm {
  font-size: 16PX;
  margin:16PX 0 10PX 0;
  color: #333;
}
.admiss-con-sm {
  color: #999999;
  font-size: 14PX;
::v-deep{
line-height: 24PX;
}
}

.admiss-icon-sm {
  display: flex;
  flex-direction: row;
  margin-top: 13PX;
  font-size: 16PX;
}

.admiss-icon-sm > div {
  margin-right: 26PX;
}

.admiss-icon-sm img {
  width: 20PX;
  height: 20PX;
  vertical-align: -3PX;
}
.companypng-sm {
  width: 100%;
  height: 362PX;
  margin-top: 16PX;
}
.admiss-service-main{
  width: 100vw;
  position: relative;
  left: -5vw;
}
.main-service-bot-sm{
  height: 450PX;
  margin: 0 auto;
  padding: 20PX 0;
  background: #fff;
  width: 100vw;
  position: relative;
  left: -5vw;
} 
.service-con-sm {
  width: 90vw;
  position: absolute;
  top: 10PX;
  left: 5vw;
}

.servicetop-sm {
  /* width: 150px; */
  font-size: 20PX;
  color: #333;
  font-weight: bold;
  padding: 10PX 0;
  border-radius: 3PX;
  margin: 0 auto;
  text-align: center;
}

.servicebot-sm {
  width: 69PX;
  height: 3PX;
  background: #FE992B;
  border-radius: 3PX;
  margin: 0 auto;
}
.english-yellow-sm {
  color: #FE992B;
  font-size: 20PX;
  opacity: 0.3;
  font-weight: bold;
  text-align: center;
}

.all-service-company-sm{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20PX;
}
.all-service-company-sm>div,.all-service-company-sm>div img{
  width:110PX;
  height: 60PX;
  margin-bottom: 20PX;
}
</style>